import React, { useState } from 'react'
import { Route, HashRouter, Routes } from 'react-router-dom'
import Sources from './Sources'
import Customers from './Customers'

import '../App.css'
import ResponsiveAppBar from './AppBar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Snackbar } from '@mui/material'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref,) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


export type Props = {
    user: any
    snackbar: CallableFunction
}

type HomeProps = {
    user: any
}

function Home({ user }: HomeProps) {
    const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null)

    function handleCloseSnackbar() { setSnackbar(null) }

    const props = {
        user: user,
        snackbar: setSnackbar
    }

    return (
        <div id="home-page">
            <HashRouter>
                <ResponsiveAppBar props={props} />
                <Routes>
                    <Route path="/" element={<Sources props={props} />} />
                    <Route path="/sources" element={<Sources props={props} />} />
                    <Route path="/customers" element={<Customers props={props} />} />
                </Routes>
                {!!snackbar && (
                    <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000} >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </HashRouter>
        </div>
    )
}

export default Home