import React, { useEffect, useState } from 'react'
import firebase from './services/firebase'
import { getUser } from './services/firebase'
import './App.css'
import Login from './components/Login'
import Home from './components/Home'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const darkTheme = createTheme({
    palette: {
        background: {
            default: '#f0f0f0'
        },
        mode: 'light',
    }
})

function App() {
    const [user, setUser] = useState(null)

    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user: any) => {
            setUser(user)
            if (user) {
                return getUser()
                    .catch(async () => {
                        return firebase.auth().signOut()
                    })
            }
        })
    }, [])

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            {user
                ? <Home user={user} />
                : <Login />
            }
        </ThemeProvider>
    )
}

export default App
