import React, { useEffect, useState } from "react"
import Box from '@mui/material/Box'
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid'
import { Backdrop, Card, CardContent, Chip, Grid, Paper, Typography } from "@mui/material"
import { collection, limit, onSnapshot, orderBy, query, QuerySnapshot, where } from "firebase/firestore"
import { db } from "../services/firebase"
import { Props } from "./Home"
import EventDetails, { SelectedEvent } from "./EventDetails"

const eventColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200, hide: true },
    { field: 'receivedAt', type: 'string', headerName: 'Date', width: 130 },
    { field: 'type', type: 'string', headerName: 'Type', width: 80 },
    { field: 'processor', type: 'string', headerName: 'Processor', width: 100 },
    { field: 'customerId', type: 'string', headerName: 'CustomerId', width: 200 },
    { field: 'event', type: 'string', headerName: 'event', width: 120 },
    { field: 'email', type: 'string', headerName: 'email', width: 200 },
    { field: 'data', type: 'string', headerName: 'data', hide: true, width: 200 }
]

type SourceDetailsProps = {
    props: Props
    selectedSource: any
}

function SourceDetails({ props, selectedSource }: SourceDetailsProps) {
    const [events, setEvents] = useState<any[]>([])
    const [selectedEvent, setSelectedCustomer] = useState<SelectedEvent | null>(null)
    const [eventOpen, setEventOpen] = useState<boolean>(false)

    function closeEventBackdrop() { setEventOpen(false) }
    function toggleEventBackdrop() { setEventOpen(!eventOpen) }

    async function cellClicked(params: GridCellParams) {
        setSelectedCustomer({ row: params.row })
        toggleEventBackdrop()
    }

    function useEffectUpdateEventStream() {
        function updateTable(querySnapshot: QuerySnapshot) {
            const rows: any[] = []
            querySnapshot.forEach((doc) => {
                const data = doc.data()
                rows.push({
                    id: doc.id,
                    receivedAt: data.receivedAt.toDate().getTime() / 1000,
                    type: data.type,
                    processor: data.processor,
                    sourceId: data.sourceId,
                    customerId: data.customerId,
                    data: data.data,
                    email: data.data.data.contact.email,
                    event: data.data.event,
                    headers: data.headers
                })
            })
            setEvents(rows)
        }
        if (selectedSource?.row?.id) {
            const sourceId = selectedSource.row.id
            return onSnapshot(
                query(
                    collection(db, `/accounts/${props.user.uid}/events`),
                    where('sourceId', '==', sourceId),
                    orderBy("receivedAt", "desc"),
                    limit(50)
                ),
                updateTable
            )
        }
    }

    useEffect(useEffectUpdateEventStream, [props.user.uid, selectedSource?.row.id])

    return (
        <div>
            {!!selectedEvent &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer }} open={eventOpen} >
                    <EventDetails event={selectedEvent} close={closeEventBackdrop} />
                </Backdrop>
            }
            {!!selectedSource &&
                <Grid item>
                    <Box
                        sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: 900, minHeight: 250 } }}>
                        <Card>
                            <CardContent>
                                <Typography variant="button" component="div"> Source Details </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Event Endpoint ID: <Chip label={selectedSource.endpointId} />
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Webhook URL: <Chip
                                        label={`https://us-central1-cake-cdp-int.cloudfunctions.net/webhooks/${selectedSource.endpointId}`} />
                                </Typography>
                                <Typography variant="button" component="div"> Events </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    '& > :not(style)': { paddingTop: 1, m: 0, width: '100%', minHeight: 100 }
                                }}>
                                    <Paper elevation={0}>
                                        <DataGrid
                                            onCellClick={cellClicked}
                                            autoHeight
                                            density='compact'
                                            pagination
                                            pageSize={8}
                                            rowsPerPageOptions={[8]}
                                            aria-label="simple table"
                                            rows={events}
                                            columns={eventColumns}
                                            disableSelectionOnClick
                                        />
                                    </Paper>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            }
        </div>
    )
}

export default SourceDetails