import firebase from 'firebase/compat/app'
import { getApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import 'firebase/compat/auth'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyARfF2tmr1sTymHKmWbs9hcxHnGHMnPn6Y",
    authDomain: "cake-cdp-int.firebaseapp.com",
    databaseURL: "https://cake-cdp-int-default-rtdb.firebaseio.com",
    projectId: "cake-cdp-int",
    storageBucket: "cake-cdp-int.appspot.com",
    messagingSenderId: "1028700733932",
    appId: "1:1028700733932:web:37af2145d743962e6f00c9",
    measurementId: "G-NG3363EG7J"
}

firebase.initializeApp(firebaseConfig)

// FUNCTIONS
const functions = getFunctions(getApp())
if (window.location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, "localhost", 5001)
}

// FIRESTORE
export const db: any = getFirestore(getApp())
if (window.location.hostname === 'localhost') {
    connectFirestoreEmulator(db, "localhost", 8080)
}

// AUTH
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
googleAuthProvider.setCustomParameters({ prompt: 'select_account' })
export const auth = firebase.auth()

// ANALYTICS
export const analytics = getAnalytics(getApp())

// GOOGLE LOGIN
export const signInWithGoogle = async () => {
    await auth.signInWithPopup(googleAuthProvider)
}

const githubAuthProvider = new firebase.auth.GithubAuthProvider()

export const signInWithGitHub = async () => {
    await auth.signInWithPopup(githubAuthProvider)
}

export default firebase
export const getUser = httpsCallable(functions, 'getUser')