import React from "react"
import Box from '@mui/material/Box'
import { Button, Card, CardContent, Chip, Grid, Typography } from "@mui/material"
import JSONPretty from "react-json-pretty"
import { GridRowModel } from "@mui/x-data-grid"
import 'react-json-pretty/themes/1337.css'

type EventDetailsProps = {
    event: SelectedEvent
    close: any
}

export type SelectedEvent = {
    row: GridRowModel
}

function EventDetails({ event, close }: EventDetailsProps) {
    let formattedEvent: any

    formattedEvent = event.row

    return (
        <div>
            {!!formattedEvent &&
                <Grid item>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: 800, minHeight: 250 } }}>
                        <Card>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Event ID: <Chip label={formattedEvent.id} />
                                </Typography>
                                <Button onClick={close} variant="contained">Close</Button>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { paddingTop: 0, m: 0, width: '100%', minHeight: 100, bgcolor: '#1e1e1e' } }}>
                                    <JSONPretty data={formattedEvent} mainStyle="padding-left:1em" style={{ marginTop: '1rem', overflow: 'auto', maxHeight: '80vh' }} />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            }
        </div>
    )
}

export default EventDetails