import React, { useEffect, useState } from 'react'
import { Box, Container, Grid, Paper, } from '@mui/material'
import { DataGrid, GridCellParams, GridColDef, GridRowModel } from '@mui/x-data-grid'
import { collection, limit, onSnapshot, orderBy, query, QuerySnapshot, where } from 'firebase/firestore'
import { db } from '../services/firebase'
import { Props } from './Home'
import CustomerDetails from './CustomerDetails'

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200, hide: false },
    { field: 'email', headerName: 'email', width: 300 },
]
type SelectedCustomer = {
    row: GridRowModel
}

type CustomersProps = {
    props: Props
}

const Customers = ({ props }: CustomersProps) => {
    const [customers, setCustomers] = useState<any[]>([])
    const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer | null>(null)

    async function cellClicked(params: GridCellParams) {
        setSelectedCustomer({ row: params.row })
    }

    function useEffectUpdateDataGrid() {
        function updateTable(querySnapshot: QuerySnapshot) {
            const rows: any[] = []
            querySnapshot.forEach((doc) => {
                const data = doc.data()
                rows.push({
                    id: doc.id,
                    lookupId: data.lookupId,
                    email: data.traits?.email || null
                })
            })
            setCustomers(rows)
        }
        return onSnapshot(
            query(
                collection(db, `/accounts/${props.user.uid}/customers`),
                where('status', '==', 'active'),
                orderBy('updatedAt', 'desc')
            ),
            updateTable
        )
    }
    useEffect(useEffectUpdateDataGrid, [props.user.uid])

    return (
        <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
            <Grid container>
                <Grid item>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap', '& > :not(style)': {
                            m: 1,
                            width: 520,
                            height: 600
                        }
                    }}>
                        <Paper>
                            <DataGrid
                                onCellClick={cellClicked}
                                pagination
                                pageSize={9}
                                rowsPerPageOptions={[9]}
                                // other
                                aria-label="simple table"
                                rows={customers}
                                columns={columns}
                                disableSelectionOnClick
                            />
                        </Paper>
                    </Box>
                </Grid>
                <CustomerDetails props={props} selectedCustomer={selectedCustomer} />
            </Grid>
        </Container>
    )
}

export default Customers