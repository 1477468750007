import React, { useState } from "react"
import { Button, Card, CardActions, CardContent, TextField, Typography } from "@mui/material"
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { addDoc, collection } from 'firebase/firestore'
import { db } from "../services/firebase"
import { Props } from "./Home"

type AddSourceProps = {
    props: Props
    close: any
}

function AddSource({ props, close }: AddSourceProps) {
    const [type, setType] = useState('')
    const [name, setName] = useState('')

    const handleTypeChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string)
    }
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value as string)
    }

    async function addSource() {
        const payload = {
            accountId: props.user.uid,
            name: name,
            type: type,
            status: 'creating'
        }

        addDoc(collection(db, `accounts/${props.user.uid}/sources`), payload)
            .then(() => {
                props.snackbar({ children: 'Source successfully created', severity: 'success' })
            })
            .catch(() => {
                props.snackbar({ children: 'Error creating source', severity: 'error' })
            })
        close()
    }

    return (
        <div id="source-add">
            <Card sx={{ minWidth: 500 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
                        Create a Source
                    </Typography>
                    <Box sx={{ minWidth: 120 }}>
                        <Box
                            component="form"
                            sx={{ '& > :not(style)': { m: 0, width: '100%', paddingBottom: 5 } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField id="sourceName" label="Name" variant="standard" onChange={handleNameChange} />
                        </Box>
                        <FormControl fullWidth>
                            <InputLabel id="sourceType">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Type"
                                onChange={handleTypeChange}
                            >
                                <MenuItem value={'cakemail'}>Cakemail</MenuItem>
                                <MenuItem value={'analytics'}>Website Analytics</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>
                <CardActions>
                    <Button onClick={addSource} variant="contained" size="small">Create</Button>
                    <Button onClick={close} size="small">Cancel</Button>
                </CardActions>
            </Card>
        </div>
    )
}

export default AddSource