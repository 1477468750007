import React, { useEffect, useState } from "react"
import Box from '@mui/material/Box'
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid'
import { Backdrop, Card, CardContent, ClickAwayListener, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { collection, doc, DocumentSnapshot, limit, onSnapshot, orderBy, query, QuerySnapshot, where } from "firebase/firestore"
import { db } from "../services/firebase"
import EventDetails, { SelectedEvent } from "./EventDetails"
import { Props } from "./Home"

const eventColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200, hide: true },
    { field: 'receivedAt', type: 'string', headerName: 'Date', width: 130 },
    { field: 'type', sortable: true, headerName: 'type', width: 80 },
    { field: 'processor', sortable: true, headerName: 'processor', width: 100 },
    { field: 'sourceId', sortable: true, headerName: 'sourceId', width: 200 },
    { field: 'data', type: 'string', headerName: 'data', hide: true, width: 200 }
]

type CustomerDetailsProps = {
    props: Props
    selectedCustomer: any
}

function CustomerDetails({ props, selectedCustomer }: CustomerDetailsProps) {
    function jsxTraits(traits: any): JSX.Element {
        let output: JSX.Element[] = []
        for (const key of Object.keys(traits)) {
            output.push(
                <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell>{traits[key]}</TableCell>
                </TableRow>
            )
        }
        return <div>{output}</div>
    }

    const [traits, setCustomerTraits] = useState<any>(null)
    const [events, setEvents] = useState<any[]>([])
    const [selectedEvent, setSelectedCustomer] = useState<SelectedEvent | null>(null)
    const [eventOpen, setEventOpen] = useState<boolean>(false)

    function closeEventBackdrop() { setEventOpen(false) }
    function toggleEventBackdrop() { setEventOpen(!eventOpen) }

    async function cellClicked(params: GridCellParams) {
        setSelectedCustomer({ row: params.row })
        toggleEventBackdrop()
    }

    function useEffectUpdateEventStream() {
        function updateTable(querySnapshot: QuerySnapshot) {
            const rows: any[] = []
            querySnapshot.forEach((doc) => {
                const data = doc.data()
                rows.push({
                    id: doc.id,
                    receivedAt: data.receivedAt.toDate().getTime() / 1000,
                    type: String(data.type || ""),
                    processor: data.processor,
                    sourceId: data.sourceId,
                    customerId: data.customerId,
                    data: data.data,
                    headers: data.headers
                })
            })
            setEvents(rows)
        }
        if (selectedCustomer?.row.id) {
            const customerId = selectedCustomer.row.id
            return onSnapshot(
                query(
                    collection(db, `/accounts/${props.user.uid}/events`),
                    where('customerId', '==', customerId),
                    orderBy('receivedAt', 'desc'),
                    limit(50)
                ),
                updateTable
            )
        }
    }

    function useEffectUpdateCustomer() {
        function updateTraits(docSnap: DocumentSnapshot) {
            const t = docSnap.get('traits')
            setCustomerTraits(t)
        }
        if (selectedCustomer) {
            return onSnapshot(
                doc(db, `/accounts/${props.user.uid}/customers/${selectedCustomer.row.id}`),
                updateTraits
            )
        }
    }

    useEffect(useEffectUpdateCustomer, [props.user.uid, selectedCustomer])
    useEffect(useEffectUpdateEventStream, [props.user.uid, selectedCustomer])

    return (
        <div>
            {!!selectedEvent &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer }} open={eventOpen}>
                    <EventDetails event={selectedEvent} close={closeEventBackdrop} />
                </Backdrop>
            }
            {!!selectedCustomer &&
                <Grid item>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: 800, minHeight: 250 } }}>
                        <Card>
                            <CardContent>
                                <Typography variant="button" component="div">
                                    Customer Details
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { paddingTop: 1, m: 0, width: '100%', minHeight: 100 } }}>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {!!traits &&
                                                    jsxTraits(traits)
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Typography variant="button" component="div" sx={{ marginTop: 3 }}>
                                    Events
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { paddingTop: 1, m: 0, width: '100%', minHeight: 100 } }}>
                                    <Paper elevation={0}>
                                        <DataGrid
                                            autoHeight
                                            onCellClick={cellClicked}
                                            density='compact'
                                            pagination
                                            pageSize={8}
                                            rowsPerPageOptions={[8]}
                                            aria-label="simple table"
                                            rows={events}
                                            columns={eventColumns}
                                            disableSelectionOnClick
                                        />
                                    </Paper>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            }
        </div>
    )
}

export default CustomerDetails