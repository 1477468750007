import { Button, Grid } from "@mui/material"
import React from "react"
import "../App.css"
import { signInWithGitHub, signInWithGoogle } from "../services/firebase"

const Login = () => {
    return (
        <React.Fragment>
            <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
                <Grid item xs={6} justifyContent="center" >
                    <div>CAKE.IO</div>
                    <Button variant="contained" onClick={async () => { await signInWithGoogle() }} >
                        <i className="fab fa-google"></i>Sign-in with Google
                    </Button>
                    <Button variant="contained" onClick={async () => { await signInWithGitHub() }} >
                        <i className="fab fa-github"></i>Sign-in with GitHub
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default Login