import React, { useState } from "react"
import Box from '@mui/material/Box'
import { DataGrid, GridCellParams, GridColDef, GridRowModel, GridSelectionModel } from '@mui/x-data-grid'
import { analytics, db } from "../services/firebase"
import { Props } from "./Home"
import { onSnapshot, collection, QuerySnapshot, query, orderBy, setDoc, where, getDocs } from 'firebase/firestore'
import { useEffect } from "react"
import { Button, Container, Grid, Paper, Stack } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import Backdrop from '@mui/material/Backdrop'
import AddSource from "./AddSource"
import { doc, deleteDoc } from 'firebase/firestore'
import { PauseOutlined, PlayArrowOutlined } from "@mui/icons-material"
import SourceDetails from "./SourceDetails"
import { logEvent } from "firebase/analytics"

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200, hide: true },
    { field: 'name', editable: true, headerName: 'Name', width: 240 },
    { field: 'type', headerName: 'Type', width: 90 },
    { field: 'status', headerName: 'Status', width: 80 }
]

type SelectedSource = {
    row: GridRowModel
    endpointId: string
}

type SourceProps = {
    props: Props
}

function Sources({ props }: SourceProps) {
    const [sources, setSources] = useState<any[]>([])
    const [selectedSources, setSelectedSources] = useState<any[]>([])
    const [addOpen, setAddOpen] = useState<boolean>(false)
    const [selectedSource, setSelectedSource] = useState<SelectedSource | null>(null)

    function closeAddBackdrop() { setAddOpen(false) }
    function toggleAddBackdrop() { setAddOpen(!addOpen) }

    function deleteSources() {
        for (const selectedSource of selectedSources) {
            deleteDoc(doc(db, `accounts/${props.user.uid}/sources/${selectedSource}`))
                .then(() => {
                    setSelectedSource(null)
                    props.snackbar({ children: `Source ${selectedSource} successfully deleted`, severity: 'success' })
                })
                .catch((error: any) => {
                    props.snackbar({ children: 'Source could not be deleted', severity: 'error' })
                })
        }
    }

    function suspendSources() {
        selectedSources.forEach((selectedSource) => {
            setDoc(doc(db, `accounts/${props.user.uid}/sources/${selectedSource}`), { status: 'inactive' }, { merge: true })
                .then(() => {
                    props.snackbar({ children: 'Source successfully suspended', severity: 'success' })
                })
                .catch(() => {
                    props.snackbar({ children: 'Source could not be suspended', severity: 'error' })
                })
        })
    }

    function activateSources() {
        selectedSources.forEach((selectedSource) => {
            setDoc(doc(db, `accounts/${props.user.uid}/sources/${selectedSource}`), { status: 'active' }, { merge: true })
                .then(() => {
                    props.snackbar({ children: 'Source successfully activated', severity: 'success' })
                })
                .catch(() => {
                    props.snackbar({ children: 'Source could not be activated', severity: 'error' })
                })
        })
    }

    function changeSelection(selectionModel: GridSelectionModel) {
        setSelectedSources(selectionModel)
    }

    async function cellClicked(params: GridCellParams) {
        const document = await getEndpointFromSourceId(String(params.id))
        setSelectedSource({ row: params.row, endpointId: document.id })
    }

    async function getEndpointFromSourceId(sourceId: string) {
        return getDocs(query(
            collection(db, `endpoints`),
            where('accountId', '==', props.user.uid),
            where('sourceId', '==', sourceId)
        ))
            .then((qs: QuerySnapshot) => {
                return qs.docs[0]
            })
    }

    async function processRowUpdate(newRow: GridRowModel) {
        return setDoc(doc(db, `accounts/${props.user.uid}/sources/${newRow.id}`), { name: newRow.name }, { merge: true })
            .then(() => {
                logEvent(analytics, "source_added")
                props.snackbar({ children: 'Source successfully saved', severity: 'success' })
                return newRow
            })
    }

    function handleProcessRowUpdateError(error: Error) {
        props.snackbar({ children: error.message, severity: 'error' })
    }

    function useEffectUpdateDataGrid() {
        function updateTable(querySnapshot: QuerySnapshot) {
            const rows: any[] = []
            querySnapshot.forEach((doc) => {
                const data = doc.data()
                rows.push({
                    id: doc.id,
                    name: data.name,
                    type: data.type,
                    status: data.status
                })
            })
            setSources(rows)
        }
        return onSnapshot(
            query(
                collection(db, `/accounts/${props.user.uid}/sources`,),
                orderBy("status", "asc"),
            ),
            updateTable
        )
    }

    useEffect(useEffectUpdateDataGrid, [props.user.uid])

    return (
        <Container maxWidth="xl" sx={{ paddingTop: 2 }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer }} open={addOpen} >
                <AddSource props={props} close={closeAddBackdrop} />
            </Backdrop>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: '100%', } }}>
                <Stack direction="row" spacing={2}>
                    <Button onClick={toggleAddBackdrop} variant="contained" startIcon={<ControlPointOutlinedIcon />}>
                        Add
                    </Button>
                    <Button onClick={suspendSources} variant="contained" startIcon={<PauseOutlined />}>
                        Suspend
                    </Button>
                    <Button onClick={activateSources} variant="contained" startIcon={<PlayArrowOutlined />}>
                        Activate
                    </Button>
                    <Button onClick={deleteSources} variant="contained" startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                </Stack>
            </Box>

            <Grid container>
                <Grid item>
                    <Box minWidth={500} sx={{ display: 'flex', flexWrap: 'wrap', '& > :not(style)': { m: 1, width: '100%', height: 600 } }}>
                        <Paper>
                            <DataGrid
                                onSelectionModelChange={changeSelection}
                                onCellClick={cellClicked}
                                processRowUpdate={processRowUpdate}
                                onProcessRowUpdateError={handleProcessRowUpdateError}
                                experimentalFeatures={{ newEditingApi: true }}
                                // pagination
                                pagination
                                pageSize={9}
                                rowsPerPageOptions={[9]}
                                // other
                                aria-label="simple table"
                                rows={sources}
                                columns={columns}
                                checkboxSelection
                                disableSelectionOnClick
                            />
                        </Paper>
                    </Box>
                </Grid>
                <SourceDetails selectedSource={selectedSource} props={props} />
            </Grid>
        </Container>
    )
}

export default Sources